import Vue from 'vue'
import axios from 'axios'

const initState = {
  currentPage: 'index',
  index: {
    businesses: null,
    total: 0,
    queries: {
      per_page: 10,
      page: 1,
      status: 'active',
      prospect_status:'all',
      search: null,
      sort_by: 'id',
      sort_desc: false,
    },
  },
  archived: {
    businesses: null,
    total: 0,
    queries: {
      per_page: 10,
      page: 1,
      status: 'archived',
      search: null,
      sort_by: 'id',
      sort_desc: false,
    },
  },
}

export default {
  namespaced: true,

  state: Vue.util.extend({}, initState),

  mutations: {
    getAllSuccess(state, { businesses, page, total, queries }) {
      state[page] = {
        businesses: businesses,
        total: total,
        queries: queries,
      }
    },

    createSuccess(state, { business }) {
      state.index.businesses = null
    },

    updateSuccess(state, { id, business }) {
      state.index.businesses = null
    },

    archiveSuccess(state) {
      state.archived.businesses = null
    },

    restoreSuccess(state) {
      state.index.businesses = null
    },

    editSettingsSuccess(state, user) {
      this.state.auth.user = user
    },

    updateBroadcastersSuccess(state, user) {
      this.state.auth.user = user
    },

    resetAllSuccess(state) {
      for (let f in state) {
        Vue.set(state, f, initState[f])
      }
    },

    currentPage(state, page) {
      state.currentPage = page
    },
  },

  actions: {
    getAll({ commit }, { queries, page }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/businesses', { params: queries })
          .then((res) => {
            commit('getAllSuccess', {
              businesses: res.data.data,
              page: page,
              total: res.data.meta.total,
              queries: queries,
            })
            resolve(true)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    find({ commit }, businessId) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/businesses/${businessId}`)
          .then((res) => {
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    create({ commit }, business) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/businesses/create-manually`, business)
          .then((res) => {
            commit('createSuccess', { business: res.data.data })
            resolve(res.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    update({ commit }, business) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/businesses/${business.id}`, business)
          .then((res) => {
            commit('updateSuccess', {
              id: business.id,
              business: res.data.data,
            })
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    gateUpdate({ commit }, business) {
      return new Promise((resolve, reject) => {
        axios
            .put(`/gate-update/${business.id}`, business)
            .then((res) => {
              commit('updateSuccess', {
                id: business.id,
                business: res.data.data,
              })
              resolve(res.data.data)
            })
            .catch((error) => {
              reject(error)
            })
      })
    },

    optIn({ commit }, business) {
      return new Promise((resolve, reject) => {
        axios
            .put(`/businesses-optin/${business.id}`, business)
            .then((res) => {
              commit('updateSuccess', {
                id: business.id,
                business: res.data.data,
              })
              resolve(res.data.data)
            })
            .catch((error) => {
              reject(error)
            })
      })
    },

    archive({ commit }, businessId) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/businesses/${businessId}`)
          .then(() => {
            commit('archiveSuccess')
            resolve(true)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    delete({ commit }, businessId) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/businesses/${businessId}`)
          .then(() => {
            resolve(true)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    restore({ commit }, businessId) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/businesses/${businessId}/restore`)
          .then((res) => {
            commit('restoreSuccess')
            resolve(true)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    getReplyEngineStats({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios
            .get(params.end !== null && params.start !== null ? `/re-stats/${params.id}/${params.start}/${params.end}` : `/re-stats/${params.id}`)
            .then((res) => {
              // this.widget = res.data.data;
              resolve(res.data.data)
            })
            .catch((error) => {
              reject(error)
            })
      })
    },

    resetAll({ commit }) {
      commit('resetAllSuccess')
    },

    setCurrentPage({ commit }, page) {
      commit('currentPage', page)
    },

    setFBPage({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios
            .post(`/businesses/set-fbpage/${params.token_id}`, params)
            .then((res) => {
              resolve(res.data)
            })
            .catch((error) => {
              reject(error)
            })
      })
    },
    getCurrentConnection({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios
            .get(`/businesses/get-fb-connection/${params.token_id}`, params)
            .then((res) => {
              resolve(res.data)
            })
            .catch((error) => {
              reject(error)
            })
      })
    },
    queryimages({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios
            .post(`/imagesearch`, params)
            .then((res) => {
              resolve(res.data)
            })
            .catch((error) => {
              reject(error)
            })
      })
    },
    saveImage({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios
            .post(`/imagesave`, params)
            .then((res) => {
              resolve(res.data)
            })
            .catch((error) => {
              reject(error)
            })
      })
    },
      reOrderReviewSite({ commit }, params) {
          return new Promise((resolve, reject) => {
              axios
                  .post(`/businesses/${params.business_id}/reorder-sites`, params)
                  .then((res) => {
                      resolve(res.data)
                  })
                  .catch((error) => {
                      reject(error)
                  })
          })
      },
    addUpdateReviewSite({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios
            .post(`/businesses/${params.get('business_id')}/add-update-site`, params,{
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            })
            .then((res) => {
              resolve(res.data)
            })
            .catch((error) => {
              reject(error)
            })
      })
    },
      updateContactSeries({ commit }, params) {
          return new Promise((resolve, reject) => {
              axios
                  .post(`/businesses/${params.business_id}/update-contact-series`, params)
                  .then((res) => {
                      resolve(res.data)
                  })
                  .catch((error) => {
                      reject(error)
                  })
          })
      },
    bulkAddReviews({ commit }, params) {
        return new Promise((resolve, reject) => {
            axios
                .post(`/businesses/${params.get('business_id')}/add-bulk-upload`, params,{
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    imagepage({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios
            .post(`/imagepage`, params)
            .then((res) => {
              resolve(res.data)
            })
            .catch((error) => {
              reject(error)
            })
      })
    },
    saveSocialSelectedSettings({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios
            .post(`/businesses/save-social-settings/${params.business_id}`, params)
            .then((res) => {
              resolve(res.data)
            })
            .catch((error) => {
              reject(error)
            })
      })
    },

    saveSocialThemeSettings({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios
            .post(`/businesses/save-social-theme-settings/${params.business_id}`, params)
            .then((res) => {
              resolve(res.data)
            })
            .catch((error) => {
              reject(error)
            })
      })
    },

    editSettings({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/businesses/edit-settings`, params)
          .then((res) => {
            commit('editSettingsSuccess', res.data.data)
            resolve(true)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    editTwilioSettings({ commit }, params) {
      return new Promise((resolve, reject) => {
          axios
              .put(`/businesses/${params.business_id}/edit-twilio-settings`, {...params.settings})
              .then((res) => {
                  commit('editSettingsSuccess', res.data.data)
                  resolve(true)
              })
              .catch((error) => {
                  reject(error)
              })
      })
    },
    editSocialSettings({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios
            .put(`/businesses/edit-social-settings`, params)
            .then((res) => {
              commit('editSettingsSuccess', res.data.data)
              resolve(true)
            })
            .catch((error) => {
              reject(error)
            })
      })
    },
    editSocialFusionSettings({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios
            .put(`/businesses/edit-social-fusion-settings`, params)
            .then((res) => {
              commit('editSettingsSuccess', res.data.data)
              resolve(true)
            })
            .catch((error) => {
              reject(error)
            })
      })
    },
  },

  getters: {
    all(state) {
      return state[state.currentPage].businesses
    },

    queries(state) {
      return state[state.currentPage].queries
    },

    total(state) {
      return state[state.currentPage].total
    },
  },
}
