import lazyLoading from './lazyLoading'
import DashboardLayout from '@layouts/Dashboard'
import AdminGuard from './guards/admin-guard'

export default [
  {
    path: '/admin/',
    component: DashboardLayout,
    beforeEnter: AdminGuard,
    redirect: { name: 'admin.agencies.index' },
    children: [
      {
        name: 'admin.users.index',
        path: 'users',
        component: lazyLoading('Admin/Users/Index'),
        beforeEnter: AdminGuard,
      },
       {
        name: 'admin.tracking.index',
        path: 'tracking',
        component: lazyLoading('Admin/Tracking'),
        beforeEnter: AdminGuard,
      },
      {
        name: 'admin.settings.index',
        path: 'settings',
        component: lazyLoading('Admin/Settings'),
        beforeEnter: AdminGuard,
      },
      {
        name: 'admin.settings.training',
        path: 'training',
        component: lazyLoading('Admin/Training'),
        beforeEnter: AdminGuard,
      },
      {
        name: 'admin.settings.emails',
        path: 'emails',
        component: lazyLoading('Admin/Emails'),
        beforeEnter: AdminGuard,
      },
      {
        name: 'admin.agencies.index',
        path: 'agencies',
        component: lazyLoading('Admin/Agencies/Index'),
        beforeEnter: AdminGuard,
      },
      {
        name: 'admin.agencies.archived',
        path: 'agencies/archived',
        component: lazyLoading('Admin/Agencies/Archived'),
        beforeEnter: AdminGuard,
      },
      {
        name: 'admin.agencies.create',
        path: 'agencies/create',
        component: lazyLoading('Admin/Agencies/Create'),
        beforeEnter: AdminGuard,
      },
      {
        name: 'admin.agencies.edit',
        path: 'agencies/:id/edit',
        component: lazyLoading('Admin/Agencies/Edit'),
        beforeEnter: AdminGuard,
      },
      {
        name: 'admin.businesses.index',
        path: 'businesses',
        component: lazyLoading('Admin/Businesses/Index'),
        beforeEnter: AdminGuard,
      },
      {
        name: 'admin.businesses.archived',
        path: 'businesses/archived',
        component: lazyLoading('Admin/Businesses/Archived'),
        beforeEnter: AdminGuard,
      },
      {
        name: 'admin.businesses.edit',
        path: 'businesses/:id/edit',
        component: lazyLoading('Admin/Businesses/Edit'),
        beforeEnter: AdminGuard,
      },
      {
        name: 'admin.profile',
        path: 'profile',
        component: lazyLoading('Common/Profile'),
        beforeEnter: AdminGuard,
      },
      {
        name: 'admin.whitelabels.index',
        path: 'whitelabels',
        component: lazyLoading('Admin/Whitelabels/Index'),
        beforeEnter: AdminGuard,
      },
      {
        name: 'admin.plans.index',
        path: 'plans',
        component: lazyLoading('Admin/Plans/Index'),
        beforeEnter: AdminGuard,
      },
      {
        name: 'admin.plans.edit',
        path: 'plans/:id/edit',
        component: lazyLoading('Admin/Plans/Edit'),
        beforeEnter: AdminGuard,
      },
      {
        name: 'admin.plans.create',
        path: 'plans/create',
        component: lazyLoading('Admin/Plans/Create'),
        beforeEnter: AdminGuard,
      },
      {
        name: 'admin.notifications.index',
        path: 'notifications',
        component: lazyLoading('Common/Notification'),
        beforeEnter: AdminGuard,
      },
      {
        name: 'admin.notifications.create',
        path: 'notifications/create',
        component: lazyLoading('Admin/Notifications/Create'),
        beforeEnter: AdminGuard,
      },
    ],
  },
]
