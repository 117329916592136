import Vue from 'vue'
import axios from 'axios'

const initState = {
  currentPage: 'logs',
  logs: {
    logs: null,
    feedback:null,
    total: 0,
    queries: {
      per_page: 10,
      page: 1,
      status: 'active',
      search: null,
      sort_by: 'id',
      sort_desc: false,
    },
  }
}

export default {
  namespaced: true,

  state: Vue.util.extend({}, initState),

  mutations: {
    getAllSuccess(state, { logs, page, total, queries, meta }) {
      console.log(page);
      state[page] = {
        logs: logs,
        total: total,
        queries: queries,
        meta:meta
      }
    },
    updateSuccess(state, { id, reviews }) {

    },

    updateBroadcastersSuccess(state, user) {
      this.state.auth.user = user
    },

    resetAllSuccess(state) {
      for (let f in state) {
        Vue.set(state, f, initState[f])
      }
    },

    currentPage(state, page) {
      state.currentPage = page
    },
  },

  actions: {
    getAll({ commit }, { queries, page, businessId, agencyId }) {
      return new Promise((resolve, reject) => {
        axios
            .post(`/find-review-request-log`,{ params: queries, business_id:businessId, agency_id:agencyId })
            .then((res) => {
              commit('getAllSuccess', {
                logs: res.data.data,
                page: page,
                total: res.data.total,
                queries: queries
              })
              resolve(res.data)
            })
            .catch((error) => {
              reject(error)
            })
      })
    },

    resetAll({ commit }) {
      commit('resetAllSuccess')
    },

    setCurrentPage({ commit }, page) {
      commit('currentPage', page)
    },

  },

  getters: {
    all(state) {
      return state[state.currentPage].logs ?? []
    },

    queries(state) {
      return state[state.currentPage].queries
    },

    total(state) {
      return state[state.currentPage].total
    },

    meta(state) {
      return state[state.currentPage].meta
    },
  },
}
