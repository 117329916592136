import store from '@store/index'

function AdminGuard(to, from, next) {
  let user = store.getters['auth/user']

  if (!user) {
    next({ name: 'login' })
  } else if (user.role !== 'Admin') {
    next('/')
  } else {
    next()
  }
}

export default AdminGuard
