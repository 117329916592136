import Vue from 'vue'
import axios from 'axios'

const initState = {
  plans: null,
}

export default {
  namespaced: true,

  state: Vue.util.extend({}, initState),

  mutations: {
    getAllSuccess(state, plans) {
      state.plans = plans.sort((a, b) => {
        return a.amount - b.amount
      })
    },

    subscribeSuccess(state, plan) {
      this.state.auth.user = {
        ...this.state.auth.user,
        stripe_plan: plan,
        subscribed: true,
      }
    },

    createPlanSuccess(state, plan) {
      if (state.plans) {
        state.plans.push(plan)
      }
    },

    updatePlanSuccess(state, plan) {
      if (state.plans) {
        state.plans = state.plans.map((p) => (p.id === plan.id ? plan : p))
      }
    },

    deletePlanSuccess(state, planId) {
      let planIndex = state.plans.findIndex((plan) => plan.id === planId)

      state.plans.splice(planIndex, 1)
    },

    resumeSuccess(state, user) {
      this.state.auth.user = user
    },

    resetAllSuccess(state) {
      for (let f in state) {
        Vue.set(state, f, initState[f])
      }
    },
  },

  actions: {
    resetAll({ commit }) {
      commit('resetAllSuccess')
    },

    getAllPlans({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`subscriptions/plans`)
          .then((res) => {
            console.log(res);
            commit('getAllSuccess', res.data.data)
            resolve(true)
          })
          .catch(() => {})
      })
    },

    findPlan({ commit }, planId) {
      return new Promise((resolve, reject) => {
        axios
          .get(`plans/${planId}`)
          .then((res) => {
            resolve(res.data.data)
          })
          .catch(() => {})
      })
    },

    createPlan({ commit }, plan) {
      return new Promise((resolve, reject) => {
        axios
          .post(`plans`, plan)
          .then((res) => {
            commit('createPlanSuccess', res.data.data)
            resolve(true)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    updatePlan({ commit }, plan) {
      return new Promise((resolve, reject) => {
        axios
          .put(`plans/${plan.id}`, plan)
          .then((res) => {
            commit('updatePlanSuccess', res.data.data)
            resolve(true)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    deletePlan({ commit }, planId) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`plans/${planId}`)
          .then((res) => {
            commit('deletePlanSuccess', planId)
            resolve(true)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    getPlanByName({ commit }, name) {
      return new Promise((resolve, reject) => {
        axios
          .get(`subscriptions/plans/${name}`)
          .then((res) => {
            resolve(res.data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    subscribe({ commit }, plan) {
      return new Promise((resolve, reject) => {
        axios
          .post(`subscriptions/${plan}`)
          .then((res) => {
            commit('subscribeSuccess', plan)
            resolve(true)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    subscribeUnlimited({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`subscriptions/subscribe-unlimited`)
          .then((res) => {
            resolve(true)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    cancel({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`subscriptions/${params.user_id}/cancel/${params.plan_id}`)
          .then((res) => {
            resolve(true)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    resume({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`subscriptions/resume`)
          .then((res) => {
            commit('resumeSuccess', res.data.data.user)
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
  },

  getters: {
    allPlans(state) {
      return state.plans
    },
  },
}
