import axios from 'axios'
import Vue from 'vue'

axios.defaults.baseURL = (process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_API : process.env.VUE_APP_BASE_API_LOCAL) + 'api/v1';
axios.defaults.headers.common['Authorization'] =
  'Bearer ' + localStorage.getItem('access_token')
axios.interceptors.response.use(
  (response) => {
    // Show success message
    if (response.data && response.data.message && response.data.success) {
      Vue.$toast.open({
        message: response.data.message,
        type: 'success',
        duration: 5000,
      })
    }
    return response
  },
  (error) => {
    if (error.response.status === 422) {
      // Show error message
      const { errors } = error.response.data
      const keys = Object.keys(errors);
      const message = errors[keys[0]] ? errors[keys[0]][0] : ''
      if (message) {
        Vue.$toast.open({
          message: message,
          type: 'error',
          duration: 5000,
        })
      }
    } else {
      if (error.response && error.response.status === 406) {

      } else if (error.response && error.response.data && error.response.data.message) {
        // Show error message
        Vue.$toast.open({
          message: error.response.data.message,
          type: 'error',
          duration: 5000,
        })
      }
    }

    // Catch 401 request
    if (error.response.status === 401) {
    } else {
      return Promise.reject(error)
    }
  }
)
