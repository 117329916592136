import lazyLoading from './lazyLoading'
import LandingLayout from '@layouts/Landing'

export default [
  {
    path: '/p/',
    component:LandingLayout,
    redirect: { name: 'agency.landing.index' },
    children: [
      {
        path: ':id',
        component: lazyLoading('Agency/Landing/Index'),
        name: 'agency.landing.index',
      }
    ],
  },
  {
    path: '/linknotfound',
    component: lazyLoading('Agency/Landing/NotFound'),
    name: 'notfound',
  },
  {
    path: '/r/',
    component:LandingLayout,
    redirect: { name: 'gate.short-review' },
    children: [
      {
        path: ':uuid/:employee_id?',
        component: lazyLoading('Business/ReviewGate'),
        name: 'gate.short-review',
      }
    ],
  },
  {
    path: '/c/',
    component:LandingLayout,
    redirect: { name: 'gate.card-short-review' },
    children: [
      {
        path: ':card_id',
        component: lazyLoading('Business/ReviewGate'),
        name: 'gate.card-short-review',
      }
    ],
  },
  {
    path: '/review/',
    component:LandingLayout,
    redirect: { name: 'gate.review' },
    children: [
      {
        path: ':uuid/:employee_id?',
        component: lazyLoading('Business/ReviewGate'),
        name: 'gate.review',
      }
    ],
  },
  {
    path: '/leaderboard/',
    component:LandingLayout,
    redirect: { name: 'leaderboard' },
    children: [
      {
        path: ':uuid',
        component: lazyLoading('Business/LeaderBoard'),
        name: 'leaderboard',
      }
    ],
  },
  {
    path: '/request-reviews/',
    component:LandingLayout,
    redirect: { name: 'request-reviews' },
    children: [
      {
        path: ':unique_id',
        component: lazyLoading('Business/ReviewRequestSingle'),
        name: 'request-reviews',
      }
    ],
  },
  {
    path: '/l/',
    component:LandingLayout,
    redirect: { name: 'lead-engine-landing' },
    children: [
      {
        path: ':landing_page/:unique_id?',
        component: lazyLoading('Agency/LeadsLandingPage'),
        name: 'lead-engine-landing',
      }
    ],
  },
  {
    path: '/5/',
    component:LandingLayout,
    redirect: { name: 'lead-engine-landing' },
    children: [
      {
        path: ':landing_page/:unique_id?',
        component: lazyLoading('Agency/LeadsLandingPage'),
        name: 'lead-engine-landing',
      }
    ],
  },
]
