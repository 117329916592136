import Vue from 'vue'
import axios from 'axios'

const initState = {
  currentPage: 'leads_index',
  leads_index: {
    leads: null,
    total: 0,
    queries: {
      per_page: 10,
      page: 1,
      status: 'active',
      prospect_status:'all',
      search: null,
      sort_by: 'id',
      sort_desc: false,
    },
  },
  archived: {
    businesses: null,
    total: 0,
    queries: {
      per_page: 10,
      page: 1,
      status: 'archived',
      search: null,
      sort_by: 'id',
      sort_desc: false,
    },
  },
}

export default {
  namespaced: true,

  state: Vue.util.extend({}, initState),

  mutations: {
    getAllSuccess(state, { page, total, queries, leads }) {
      state[page] = {
        total: total,
        queries: queries,
        leads:leads
      }
    },

    updateBroadcastersSuccess(state, user) {
      this.state.auth.user = user
    },

    resetAllSuccess(state) {
      for (let f in state) {
        Vue.set(state, f, initState[f])
      }
    },

    currentPage(state, page) {
      state.currentPage = page
    },
  },

  actions: {
    getAnalytics({ commit }, { query, agencyId }) {
      return new Promise((resolve, reject) => {
          axios
              .get(`/lm-analytics/${agencyId}`, { params: {query:query} })
              .then((res) => {
                  resolve(res.data.data)
              })
              .catch((error) => {
                  reject(error)
              })
      })
    },
    getAllLeads({ commit }, { agencyId, params }) {
      return new Promise((resolve, reject) => {
          axios
              .get(`/agencies/lm-all-leads/${agencyId}`, { params: params })
              .then((res) => {
                  resolve(res.data)
              })
              .catch((error) => {
                  reject(error)
              })
      })
    },

    getAll({ commit }, { id, queries, page }) {
      return new Promise((resolve, reject) => {
        axios
            .get(`/agencies/campaign-leads/${id}`, { params: queries })
            .then((res) => {
              commit('getAllSuccess', {
                page: page,
                total: res.data.total,
                queries: queries,
                leads: res.data.data,
              })
              resolve(true)
            })
            .catch((error) => {
              reject(error)
            })
      })
    },

    find({ commit }, businessId) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/agencies/campaign-lead/${businessId}`)
          .then((res) => {
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    delete({ commit }, businessId) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/agencies/delete-campaign-lead/${businessId}`)
          .then(() => {
            resolve(true)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    resetAll({ commit }) {
      commit('resetAllSuccess')
    },

    setCurrentPage({ commit }, page) {
      commit('currentPage', page)
    },

    createNewLandingPage({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios
            .post(`/agencies/create-new-landing-page`,params)
            .then((resp) => {
              resolve(resp.data);
            })
            .catch((error) => {
              reject(error)
            })
      })
    },
    uploadCsv({commit},params){
      return new Promise((resolve, reject) => {
          axios
              .post(`/agencies/search-upload-data/${params.get('agency_id')}`, params,{
                  headers: {
                      'Content-Type': 'multipart/form-data',
                  }
              })
              .then((res) => {
                  resolve(res.data)
              })
              .catch((error) => {
                  reject(error)
              })
      })
    },
    getLandingPages({ commit }, agencyId) {
      return new Promise((resolve, reject) => {
        axios
            .get(`/agencies/get-landing-pages/${agencyId}`)
            .then((resp) => {
              resolve(resp.data);
            })
            .catch((error) => {
              reject(error)
            })
      })
    },
    getLandingPage({ commit }, pageId) {
      return new Promise((resolve, reject) => {
        axios
            .get(`/agencies/get-landing-page/${pageId}`)
            .then((resp) => {
              resolve(resp.data);
            })
            .catch((error) => {
              reject(error)
            })
      })
    },
    getLeadAndPageData({ commit }, params) {
        let url = `/agencies/lp/${params.landingPage}/${params.uniqueId}`;
        if(params.hasOwnProperty('is_admin')){
            url += '?is_admin=true'
        }
      return new Promise((resolve, reject) => {
        axios
            .get(url)
            .then((resp) => {
              resolve(resp.data);
            })
            .catch((error) => {
              reject(error)
            })
      })
    },
    markVisited({ commit }, params) {
      return new Promise((resolve, reject) => {
          axios
              .get(`/agencies/mark-scanned/${params.landingPage}/${params.uniqueId}`)
              .then((resp) => {
                  resolve(resp.data);
              })
              .catch((error) => {
                  reject(error)
              })
      })
    },
    submitLead({ commit }, form) {
      return new Promise((resolve, reject) => {
          axios
              .post(`/agencies/submit-landing-page/${form.page_id}`,form)
              .then((resp) => {
                  resolve(resp.data);
              })
              .catch((error) => {
                  reject(error)
              })
      })
    },
    saveLandingPage({ commit }, page) {
      return new Promise((resolve, reject) => {
        axios
            .post(`/agencies/save-landing-page/${page.id}`,page)
            .then((resp) => {
              resolve(resp.data);
            })
            .catch((error) => {
              reject(error)
            })
      })
    },
    deleteLandingPage({ commit }, pageId) {
      return new Promise((resolve, reject) => {
        axios
            .delete(`/agencies/landing-page-delete/${pageId}`)
            .then(() => {
              resolve(true)
            })
            .catch((error) => {
              reject(error)
            })
      })
    },
    createNewLetter({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios
            .post(`/agencies/create-new-letter`,params)
            .then((resp) => {
              resolve(resp.data);
            })
            .catch((error) => {
              reject(error)
            })
      })
    },
    getLetters({ commit }, agencyId) {
      return new Promise((resolve, reject) => {
        axios
            .get(`/agencies/get-leads/${agencyId}`)
            .then((resp) => {
              resolve(resp.data);
            })
            .catch((error) => {
              reject(error)
            })
      })
    },
    getLetter({ commit }, letterId) {
      return new Promise((resolve, reject) => {
        axios
            .get(`/agencies/get-letter/${letterId}`)
            .then((resp) => {
              resolve(resp.data);
            })
            .catch((error) => {
              reject(error)
            })
      })
    },
    saveLetter({ commit }, letter) {
      return new Promise((resolve, reject) => {
        axios
            .post(`/agencies/save-letter/${letter.id}`,letter)
            .then((resp) => {
              resolve(resp.data);
            })
            .catch((error) => {
              reject(error)
            })
      })
    },
    deleteLetter({ commit }, letterId) {
      return new Promise((resolve, reject) => {
        axios
            .delete(`/agencies/letter-delete/${letterId}`)
            .then(() => {
              resolve(true)
            })
            .catch((error) => {
              reject(error)
            })
      })
    },
    update({ commit }, page) {
      return new Promise((resolve, reject) => {
          axios
              .put(`/agencies/update-lead/${page.id}`,page)
              .then((resp) => {
                  resolve(resp.data);
              })
              .catch((error) => {
                  reject(error)
              })
      })
    },
  },

  getters: {
    all(state) {
      return state[initState.currentPage].leads
    },

    queries(state) {
      return state[initState.currentPage].queries
    },

    total(state) {
      return state[initState.currentPage].total
    },
  },
}
