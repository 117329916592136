import axios from 'axios'

export default {
  namespaced: true,
  state: {
    access_token: localStorage.getItem('access_token'),
    loading: false,
    user: null,
    impersonating: false,
  },
  mutations: {
    loginSuccess(state, data) {
      state.access_token = data.access_token
      localStorage.setItem('access_token', data.access_token)
      axios.defaults.headers.common['Authorization'] =
        'Bearer ' + data.access_token
    },

    getProfileSuccess(state, user) {
      state.user = user
      this.dispatch('app/getConfig')
    },

    updateProfileSuccess(state, user) {
      state.user = user
    },

    logout(state) {
      state.impersonating = false
      state.user = null
      state.access_token = null
      delete axios.defaults.headers.common['Authorization']
      localStorage.removeItem('access_token')
      localStorage.removeItem('main_access_token')
      localStorage.removeItem('main_access_role')

      this.dispatch('app/getConfig', location.hostname)
      this.dispatch('user/resetAll')
      this.dispatch('business/resetAll')
      this.dispatch('paymentMethod/resetAll')
      this.dispatch('subscription/resetAll')
    },

    impersonateSuccess(state, data) {
      if (!localStorage.getItem('main_access_token')) {
        localStorage.setItem('main_access_token', state.access_token)
        localStorage.setItem('main_access_role', state.user && state.user.role || undefined)
      }

      this.dispatch('user/resetAll')
      this.dispatch('business/resetAll')
      this.dispatch('paymentMethod/resetAll')

      state.impersonating = true
      state.user = null
      localStorage.setItem('access_token', data.access_token)
      state.access_token = data.access_token
      axios.defaults.headers.common['Authorization'] =
        'Bearer ' + data.access_token
    },

    registerAgencySuccess(state, data) {
      state.access_token = data.access_token
      localStorage.setItem('access_token', data.access_token)
      axios.defaults.headers.common['Authorization'] =
        'Bearer ' + data.access_token
    },

    stopImpersonateSuccess(state, data) {
      this.dispatch('user/resetAll')
      this.dispatch('business/resetAll')
      this.dispatch('paymentMethod/resetAll')
      this.dispatch('subscription/resetAll')

      state.impersonating = false
      state.user = null
      localStorage.setItem(
        'access_token',
        localStorage.getItem('main_access_token')
      )
      state.access_token = localStorage.getItem('main_access_token')
      axios.defaults.headers.common['Authorization'] =
        'Bearer ' + localStorage.getItem('main_access_token')
      localStorage.removeItem('main_access_token')
      localStorage.removeItem('main_access_role')
    },

    loading(state, loading) {
      this.state.app.loading = loading
    },
  },
  actions: {
    login({ commit }, credentials) {
      return new Promise((resolve, reject) => {
        axios
          .post('/login', credentials)
          .then((res) => {
            commit('loginSuccess', res.data)

            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    autologin({ commit }, token) {
      return new Promise((resolve, reject) => {
        axios
            .post('/auto-login', token)
            .then((res) => {
              commit('loginSuccess', res.data)

              resolve(res)
            })
            .catch((error) => {
              reject(error)
            })
      })
    },

    forgotPassword({ commit }, credentials) {
      return new Promise((resolve, reject) => {
        axios
          .post('/passwords/reset', credentials)
          .then((res) => {
            resolve(true)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    changePassword({ commit }, credentials) {
      return new Promise((resolve, reject) => {
        axios
          .post('/passwords/change', credentials)
          .then((res) => {
            resolve(true)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    logout({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/logout')
          .then((res) => {
            commit('logout')
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    getProfile({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/profile')
          .then((res) => {
            commit('getProfileSuccess', res.data.data)

            resolve(res.data.data)
          })
          .catch((error) => {
            commit('logout')
            reject(error)
          })
      })
    },

    updateProfile({ commit }, user) {
      return new Promise((resolve, reject) => {
        axios
          .put('/profile', user)
          .then((res) => {
            commit('updateProfileSuccess', res.data.data)

            resolve(true)
          })
          .catch((error) => {
            if (error.response.status === 401) {
              commit('logout')
            }
            reject(error)
          })
      })
    },

    impersonate({ commit }, userId) {
      commit('loading', true)
      return new Promise((resolve, reject) => {
        axios
          .post(`/impersonate/${userId}`)
          .then((response) => {
            commit('loading', false)
            commit('impersonateSuccess', response.data)
            resolve(true)
          })
          .catch((e) => {
            commit('loading', false)
            reject(e)
          })
      })
    },

    stopImpersonate({ commit }) {
      commit('loading', true)
      return new Promise((resolve, reject) => {
        axios
          .delete(`/impersonate`)
          .then((res) => {
            commit('loading', false)
            commit('stopImpersonateSuccess', res.data)
            resolve(true)
          })
          .catch((e) => {
            commit('loading', false)
            reject(e)
          })
      })
    },

    registerAgency({ commit }, user) {
      return new Promise((resolve, reject) => {
        axios
          .post('/order', user)
          .then((res) => {
            commit('registerAgencySuccess', res.data.data)
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    payAgency({ commit }, user) {
      return new Promise((resolve, reject) => {
        axios
            .post('/payment', user)
            .then((res) => {
              commit('registerAgencySuccess', res.data.data)
              resolve(res)
            })
            .catch((error) => {
              reject(error)
            })
      })
    },

    addTrackingNumber({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/add-tracking', params)
            .then((res) => {
              resolve(res)
            })
            .catch((error) => {
              reject(error)
            })
      })
    },

    getUsersTrackingNumber({ commit }, userId) {
      return new Promise((resolve, reject) => {
        axios
            .get(`/get-tracking/${userId}`)
            .then((res) => {
              resolve(res)
            })
            .catch((error) => {
              reject(error)
            })
      })
    },

    deleteTrackingNumber({ commit }, trackingId) {
      return new Promise((resolve, reject) => {
        axios
            .delete(`/delete-tracking/${trackingId}`)
            .then((res) => {
              resolve(res)
            })
            .catch((error) => {
              reject(error)
            })
      })
    },
    updateTrackingNumber({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios
            .post(`/update-tracking`,params)
            .then((res) => {
              resolve(res)
            })
            .catch((error) => {
              reject(error)
            })
      })
    },

    getUnshippedTrackingNumbers({ commit }) {
      return new Promise((resolve, reject) => {
        axios
            .get(`/unshipped-tracking`)
            .then((res) => {
              resolve(res)
            })
            .catch((error) => {
              reject(error)
            })
      })
    },

  },

  getters: {
    isLoggedIn: (state) => !!state.access_token,
    isImpersonating: (state) =>
      state.impersonating || localStorage.getItem('main_access_token'),
    user: (state) => state.user,

    blockedScreen(state) {
      if (
        state.user &&
        state.user.role === 'Agency' &&
        !state.user.subscribed &&
        !state.user.agency.free_fee &&
        !state.user.agency.owner.on_trial &&
        !window.location.href.includes('agency/subscription') &&
        !window.location.href.includes('admin/agencies') &&
        !window.location.href.includes('agency/profile') &&
        !window.location.href.includes('agency/payment-methods')
      ) {
        return true
      }

      return false
    },

    dateFormat(state) {
      if (state.user && state.user.business) {
        return state.user.business.date_format
      }

      return 'MM/DD/YYYY'
    },

    birthdayFormat(state, getters) {
      return getters.dateFormat.replace(/(YYYY\/|\/YYYY)/, '');
    }
  },
}
