import store from '@store/index'

function AgencyGuard(to, from, next) {
  let user = store.getters['auth/user'];
  if (!user) {
    next({ name: 'login' })
  } else if (user.role !== 'Agency') {
    next('/')
  }else if(user.role === 'Agency' && user.has_te && to.name !== 'agency.dashboard' && (user?.address?.address === '' || user?.address?.city === '' || user?.address?.state === '' || user?.address?.zip === '' || user?.address?.country === '') ){
    next({ name: 'agency.dashboard'});
  } else if(user.agency.owner.on_trial){
    next()
  }else if (!user.subscribed && !user.agency.free_fee) {
    if (to.name !== 'agency.subscription' && to.name !== 'agency.profile' && !to.name.startsWith('agency.payment-methods')) {
      next({ name: 'agency.subscription'})
    } else {
      next()
    }
  } else {
    next()
  }
}

export default AgencyGuard
