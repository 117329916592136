import axios from 'axios'

export default {
  namespaced: true,
  actions: {
    generatePost({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
            .post(`/generate-post`,{data})
            .then((res) => {
              resolve(res.data.data)
            })
            .catch((error) => {
              reject(error)
            })
      })
    },

    regeneratePost({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
            .post(`/regenerate-post`,{data})
            .then((res) => {
              resolve(res.data.data)
            })
            .catch((error) => {
              reject(error)
            })
      })
    },

    regenerateText({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
            .post(`/regenerate-text`,{data})
            .then((res) => {
              resolve(res.data.data)
            })
            .catch((error) => {
              reject(error)
            })
      })
    },

    regenerateImage({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
            .post(`/regenerate-image`,{data})
            .then((res) => {
              resolve(res.data.data)
            })
            .catch((error) => {
              reject(error)
            })
      })
    },

    savePost({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
            .post(`/save-post`,{data})
            .then((res) => {
              resolve(res.data.data)
            })
            .catch((error) => {
              reject(error)
            })
      })
    },

    getPosts({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
            .post(`/get-posts`,{data})
            .then((res) => {
              resolve(res.data.data)
            })
            .catch((error) => {
              reject(error)
            })
      })
    },

    postSocial({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
            .post(`/post-social-fusion-image`,{data})
            .then((res) => {
              resolve(res.data.data)
            })
            .catch((error) => {
              reject(error)
            })
      })
    },
  },
}
