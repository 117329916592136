import Vue from 'vue'
import axios from 'axios'
import moment from 'moment-timezone'

const initState = {
  config: null,
  loading: false,
  currencies: [{ name: 'Dollar', label: '$ Dollar', symbol: '$' }, { name: 'Euro', label: '€ Euro', symbol: '€' }, { name: 'Pound', label: '£ Pound', symbol: '£' }],
  dateFormats: ['MM/DD/YYYY', 'DD/MM/YYYY', 'YYYY/MM/DD'],
}

export default {
  namespaced: true,

  state: Vue.util.extend({}, initState),

  mutations: {
    getConfigSuccess(state, data) {
      state.config = data
    },

    resetAllSuccess(state) {
      for (let f in state) {
        Vue.set(state, f, initState[f])
      }
    },

    editAdminSettingsSuccess(state, settings) {
      this.state.auth.user.settings = settings
    },
  },

  actions: {
    getConfig({ commit }, domain) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/config?domain=${domain}`)
          .then((res) => {
            commit('getConfigSuccess', res.data.data)
            resolve(res.data.data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    editAdminSettings({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios
            .put(`/admin/edit-settings`, params)
            .then((res) => {
              commit('editAdminSettingsSuccess', res.data.data)
              resolve(res)
            })
            .catch((error) => {
              reject(error)
            })
      })
    },

    resetAll({ commit }) {
      commit('resetAllSuccess')
    },
  },

  getters: {
    whitelabelDomain(state) {
      return state.config && state.config.whitelabel
        ? state.config.whitelabel.domain
        : ''
    },

    config(state) {
      return state.config
    },

    loading(state) {
      return state.loading
    },

    currencies(state) {
      return state.currencies
    },

    currency(state, getters, rootState) {
      let user = rootState.auth.user

      if (user && user.business) {
        let currencies = state.currencies.filter(c => c.name === user.business.currency)

        if (currencies.length) {
          return currencies[0].symbol
        }
      }

      return '$'
    },

    dateFormats(state) {
      return state.dateFormats
    },

    timezones(state) {
      // const usZones = moment.tz
      //     .zonesForCountry('US');
      // const gbZones = moment.tz
      //     .zonesForCountry('GB');

      return moment.tz.names()
        .map((name) => {
          let time = moment().tz(name);

          return {
            label: `${name} (UTC${time.format('Z')})`,
            value: name,
            sort: time.format('ZZ') * 1,
          }
        })
        // .sort((a, b) => (a.sort < b.sort ? -1 : a.sort > b.sort ? 1 : 0))
    }
  },
}
