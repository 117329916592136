import axios from 'axios';

const state = {
    widgets: [{}],
    widget: null,
    customers: []
};

const getters = {
    all: (state) => state.widgets,
    widget: (state) => state.widget,
    allCustomers: (state) => state.customers,
    customersWithoutWidgets: (state, getters) => {
        // Get all widgets and customers
        const widgets = getters.all;
        const customers = getters.allCustomers;

        // Extract business IDs from widgets
        const widgetBusinessIds = new Set(widgets.map(widget => widget.business_id));

        // Filter customers who do not have a widget
        return customers.filter(customer => !widgetBusinessIds.has(customer.id));
    }
};

const actions = {
    getAllWidgets({ commit }, { queries }) {
        return new Promise((resolve, reject) => {
            axios
                .get('/fetch-widgets', {
                    params: queries,
                })
                .then((res) => {
                    commit('SET_WIDGETS', res.data.data);
                    resolve(res.data.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    getIgWidget({ commit }, data) {
        return new Promise((resolve, reject) => {
            axios
                .post('/getWidget', data)
                .then((res) => {
                    commit('SET_WIDGET', res.data);
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    searchKeyword({ commit }, data) {
        return new Promise((resolve, reject) => {
            axios
                .post('/search-keyword', data)
                .then((res) => {
                    commit('SET_WIDGET', res.data.data);
                    resolve(res.data.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    createWidget({ commit }, data) {
        return new Promise((resolve, reject) => {
            axios
                .post('/create-widget', { data })
                .then((res) => {
                    commit('ADD_WIDGET', res.data);
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    saveEditor({ commit }, data) {
        return new Promise((resolve, reject) => {
            axios
                .post('/save-widget', { data })
                .then((res) => {
                    resolve(res);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    async fetchCustomersWithoutWidgets({ commit }) {
        try {
            const response = await axios.get('/fetch-customers-without-widgets');
            commit('SET_CUSTOMERS', response.data);
            return response.data;
        } catch (error) {
            console.error('Error fetching customers:', error);
            throw error;
        }
    },
    deleteWidget({ commit }, widgetId) {
        return new Promise((resolve, reject) => {
            axios
                .delete(`/delete-widget/${widgetId}`)
                .then((res) => {
                    resolve(res);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
};

const mutations = {
    SET_WIDGETS: (state, widgets) => {
        state.widgets = widgets;
    },
    SET_WIDGET: (state, widget) => {
        state.widget = widget;
    },
    ADD_WIDGET: (state, widget) => {
        state.widgets.push(widget);
    },
    SET_CUSTOMERS(state, customers) {
        state.customers = customers;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
