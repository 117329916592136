import Vue from 'vue'
import axios from 'axios'

const initState = {
  landingpage: null
}

export default {
  namespaced: true,

  state: Vue.util.extend({}, initState),

  mutations: {

  },

  actions: {
    resetAll({ commit }) {
      commit('resetAllSuccess')
    },
    find({ commit }, pageId) {
      return new Promise((resolve, reject) => {
        axios
            .get(`/landingpage/${pageId}`)
            .then((res) => {
              this.landingpage = res.data.data;
              resolve(res.data.data)
            })
            .catch((error) => {
              reject(error)
            })
      })
    },
      postNegativeExperience({ commit }, params){
          return new Promise((resolve,reject) => {
              axios
                  .post(`/add-feedback/${params.id}`,params)
                  .then((res) => {
                      resolve(res.data)
                  })
                  .catch((error) => {
                      reject(error)
                  })
          })
      },
      getReviewGate({ commit }, params) {
        let urlPath = params;
        if(typeof params === 'object'){
           urlPath = `${params.business_id}?${params.query_params}`;
        }
          return new Promise((resolve, reject) => {
              axios
                  .get(`/landingwidget/${urlPath}`)
                  .then((res) => {
                      // this.widget = res.data.data;
                      resolve(res.data.data)
                  })
                  .catch((error) => {
                      reject(error)
                  })
          })
      },
      getBusiness({ commit }, pageUuid) {
          return new Promise((resolve, reject) => {
              axios
                  .get(`/businesses/unique-get/${pageUuid}`)
                  .then((res) => {
                      // this.widget = res.data.data;
                      resolve(res.data)
                  })
                  .catch((error) => {
                      reject(error)
                  })
          })
      },
      submitContact({ commit }, params) {
          return new Promise((resolve, reject) => {
              axios
                  .post(`/businesses/${params.business_id}/create-single-contact`,params)
                  .then((res) => {
                      // this.widget = res.data.data;
                      resolve(res.data)
                  })
                  .catch((error) => {
                      reject(error)
                  })
          })
      },
      markVisited({commit}, params){
          return new Promise((resolve, reject) => {
              axios
                  .get(`/mark-visit/${params.business_id}/${params.employee_id}`)
                  .then((res) => {
                      // this.widget = res.data.data;
                      resolve(res);
                  })
                  .catch((error) => {
                      reject(error)
                  })
          })
      },
      recordFeedback({commit}, params){
          return new Promise((resolve, reject) => {
              axios
                  .post(`/record-feedback/${params.business_id}`, params)
                  .then((res) => {
                      // this.widget = res.data.data;
                      resolve(res);
                  })
                  .catch((error) => {
                      reject(error)
                  })
          })
      },
      findLeadMachine({commit}, params){
          return new Promise((resolve, reject) => {
              axios
                  .get(`/find-lm-lead/${params.unique_id}`, params)
                  .then((res) => {
                      // this.widget = res.data.data;
                      resolve(res);
                  })
                  .catch((error) => {
                      reject(error)
                  })
          })
      },
      getLeaderBoard({ commit }, pageUuid) {
          return new Promise((resolve, reject) => {
              axios
                  .get(`/leaderboard/${pageUuid}`)
                  .then((res) => {
                      // this.widget = res.data.data;
                      resolve(res.data.data)
                  })
                  .catch((error) => {
                      reject(error)
                  })
          })
      }
  },
}
